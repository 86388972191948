import {BaseRequest} from "@/request/BaseRequest";

class Table extends BaseRequest {

    public listWithUrl(url: string, data: any = {}): any {
        return super.listWithUrl(`${url}/list`, data);
    }

    public delWithUrl(url: string, ids: Array<string>): any {
        return super.delWithUrl(`${url}/del`, ids);
    }

    public findByIdWithUrl(url: string, id: string): any {
        return super.findByIdWithUrl(`${url}/findById`, id);
    }

    public onOffWithUrl(url: string, data: { id: string; status: number }): any {
        return super.onOffWithUrl(`${url}/onOff`, data);
    }

    public doExport(options: {url: string, data: any, fileName: string}): any {
        return new Promise(resolve => {
            this.exportWithUrl(options.url, options.data).then((res: any) => {
                let blob = new Blob([res]);
                let a = document.createElement('a');
                a.download = options.fileName;
                a.style.display = 'none';
                a.href = URL.createObjectURL(blob);
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(a.href); // 释放URL 对象
                document.body.removeChild(a);
                resolve();
            });
        })
    }
}

const c = new Table();

export {c as Table}
